import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UploadService {

    constructor(private http: HttpClient) {

    }

    uploadFile(formData){
        return this.http.post<any>(environment.wmtService+"uploadfile", formData)
    }

    cancelUpload(param){
        return this.http.post<any>(environment.wmtService+"cancel_upload", param)
    }

    history(){
        return this.http.post<any>(environment.wmtService+"search_upload_history",{});
    }

    uploadStatus(){
        return this.http.post<any>(environment.wmtService+"search_upload_status",{});
    }
}