import { User } from './../../_models/user';
import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import { site } from '../../_models/site';
import { SiteService } from '../../_services/site.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WorkService } from '../../_services/work.service';
import { WorkDetailComponent } from '../../views/work/work-detail.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})

export class DefaultLayoutComponent implements OnDestroy {

  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public userLogin: User;

  error: string;
  msgClass: string;
  loading = false;
  public site;
  sites;

  constructor(private fb: FormBuilder, private workServicce: WorkService, private router: Router, private authenticationService: AuthenticationService, @Inject(DOCUMENT) _document?: any
  ) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
    this.userLogin = this.authenticationService.currentUserValue;

  }


  ngOnInit() {
    this.getSites();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (this.authenticationService.currentUserValue != null) {
      this.navItems = this.authenticationService.currentUserValue.navBar;
    }
    console.log("user siteID : "+this.authenticationService.currentUserValue.siteID);
    for (let s of this.sites) {
      console.log(s.siteID+' '+s.siteName);
      if (s.siteID == this.authenticationService.currentUserValue.siteID) {
        this.site = s;
        this.workServicce.site = s;
        console.log('site service ' + this.workServicce.site.siteID);
        break;
      }
    }

  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  private getSites() {
    this.sites = [
      {
        siteID: 3,
        siteName: "MTP"
      },
      {
        siteID: 4,
        siteName: "AIE"
      }
    ];
  }

}