import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.post(environment.wmtService + "search_holiday", {});
  }
  delete(holiday){
    return this.http.post(environment.wmtService + "delete_holiday", {holiday});
  }

  update(holiday){
    return this.http.post(environment.wmtService + "update_holiday", {holiday});
  }

  create(holiday){
    return this.http.post(environment.wmtService + "create_holiday", {holiday});
  }
}
