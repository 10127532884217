import { EmployeeData } from './employee';
import { UserData } from './user';
export class WorkData {
    order: string;
    iw28: IW28Data;
    iw37: IW37Data[];
    iw38: IW38Data;
    manhour = new ManHourData();
    resources: ResourceData[];
    manual: ManualData = new ManualData();
    auto: AutoData = new AutoData();
    po: POData[];
    user: UserData = new UserData();
    chargehour: ChargehourData[];
    transactionLog:[];


}

export class POData {
    order;
    purchase_order;
    name_of_desired_vendor;
}

export class ChargehourData {
    ChargehourID: number;
    order: String;
    chargehour: number;
    total_per_step: number;
    oper_act:String;
}

export class AutoData {
    order;
    rel;
    create_need;
    create_today;
    percen;
    delay75;
    delay30;
    compare_wac_gk_date;
    gk_book_after_need;
}

export class ManualData {
    siteID;
    siteName;
    order;
    opt_code1;
    // opt_code2;
    // opt_code3;
    duration;
    complete_date;
    gkbook_date;
    complete_history;
    // need_procedure;
    produce_ready;
    use_procedure;
    hyperlink;
    need_full_history;
    full_history_from_tech;
    // one_page_history_from_tech;
    // need_one_page_history;
    need_report_from_vendor;
    report_from_vendor;
    sent_history_to_discipline_lead;
    return_reviewed_history;
    complete_history_date;
    high_elevated_work;
    job_complete;
    gk_expectation;
    gk_priority;
    schedule_date;
    wac_execution_date;
    scf_erection_date;
    hot_work;
    insulation;
    gatekeeper_note;
    mtn_code1;
    mtn_code2;
    mtn_code3;
    mtn_code4;
    mtn_code5;
    mtn_code6;
    note;
    swp;
    reson_of_schedule_change;
    need_manhour;
    critical_lifting;
    confined_space;
    crane_size;
    crane;
    sch_short_text;
    scaffolding;
    scaffolding_short_text;
    status_update;
    status_update_by;
    plant_name;
    own;
    lead_craft;
    firstLoad;
    isManual;
    sch;
    insulation_short_text;
    man_hour_charge;
    tech_name_charge;
    assign_manhour;
    // ie_assign_manhour;
    // ie_man_charge;
    // ee_assign_manhour;
    // ee_man_charge;
    // me_assign_manhour;
    // me_man_charge;
    // hp_assign_manhour;
    // hp_man_charge;
    // mi_assign_manhour;
    // mi_man_charge;
    // pac_assign_manhour;
    // pac_man_charge;
    // ana_assign_manhour;
    // ana_man_charge;
    // ecm_assign_manhour;
    // ecm_man_charge;
    // mi_repair_assign_manhour;
    // mi_repair_man_charge;
    // sch_assign_manhour;
    // sch_man_charge;
    ee_pa;
    ee_pc;
    ee_ac;
    hp_pa;
    hp_pc;
    hp_ac;
    ie_pa;
    ie_pc;
    ie_ac;
    me_pa;
    me_pc;
    me_ac;
    mi_pa;
    mi_pc;
    mi_ac;
    pac_pa;
    pac_pc;
    pac_ac;
    ecm_pa;
    ecm_pc;
    ecm_ac;
    ana_pa;
    ana_pc;
    ana_ac;
}

export class IW38Data {
    main_plant;
    cost_center;
    order;
    description;
    functional_location;
    discipline;
    order_type;
    main_activ_type;
    created_on;
    actual_release;
    basic_fin_date;
    planner_group;
    userstatus;
    system_status;
    priority;
    system_condition;
    free_text;
    referent_date;
    total_plnnd_costs;
    total_act_costs;
}

export class IW37Data {
    order: string;
    work_center: string;
    oper_act: string;
    opr_short_text: string;
    number: number;
    normal_duration: number;
    discipline: string;
}


export class IW28Data {
    order: string;
    description: string;
    functional_loc: string;
    system_status: string;
    notification_date: Date;
    notification: string;
}

export class ResourceData {

    resourceID: number;
    order: string;
    employee: EmployeeData;
    task: string;
    assignDate: string;
    hour: number;
    assignBy: String;
    swpNo: string;
}

export class ManHourData {
    constructor() {
        this.electrical_hour = 0;
        this.electrical_man = 0;
        this.helpers_man = 0;
        this.helpers_hour = 0;
        this.instrument_man = 0;
        this.instrument_hour = 0;
        this.mechamic_man = 0;
        this.mechamic_hour = 0;
        this.mi_Inspect_man = 0;
        this.mi_Inspect_hour = 0;
        this.mi_repair_man = 0;
        this.mi_repair_hour = 0;
        this.packing_man = 0;
        this.packing_hour = 0;
        this.ecm_hour = 0;
        this.ecm_man = 0;
        this.ana_man = 0;
        this.ana_hour = 0;
        this.sch_hour = 0;
        this.sch_man = 0;
        this.ie_assign_manhour = 0;
        this.ie_man_charge = 0;
        this.ee_assign_manhour = 0;
        this.ee_man_charge = 0;
        this.me_assign_manhour = 0;
        this.me_man_charge = 0;
        this.hp_assign_manhour = 0;
        this.hp_man_charge = 0;
        this.mi_assign_manhour = 0;
        this.mi_man_charge = 0;
        this.pac_assign_manhour = 0;
        this.pac_man_charge = 0;
        this.ana_assign_manhour = 0;
        this.ana_man_charge = 0;
        this.ecm_assign_manhour = 0;
        this.ecm_man_charge = 0;
        this.mi_repair_assign_manhour = 0;
        this.mi_repair_man_charge = 0;
        this.sch_assign_manhour = 0;
        this.sch_man_charge = 0;
    }

    leadCraft: string;
    electrical_hour: number;
    electrical_man: number;
    helpers_man: number;
    helpers_hour: number;
    instrument_man: number;
    instrument_hour: number;
    mechamic_man: number;
    mechamic_hour: number;
    mi_Inspect_man: number;
    mi_Inspect_hour: number;
    mi_repair_man: number;
    mi_repair_hour: number;
    packing_man: number;
    packing_hour: number;
    ecm_hour: number;
    ecm_man: number;
    ana_man: number;
    ana_hour: number
    sch_man: number;
    sch_hour: number;
    ie_assign_manhour: number;
    ie_man_charge: number;
    ee_assign_manhour: number;
    ee_man_charge: number;
    me_assign_manhour: number;
    me_man_charge: number;
    hp_assign_manhour: number;
    hp_man_charge: number;
    mi_assign_manhour: number;
    mi_man_charge: number;
    pac_assign_manhour: number;
    pac_man_charge: number;
    ana_assign_manhour: number;
    ana_man_charge: number;
    ecm_assign_manhour: number;
    ecm_man_charge: number;
    mi_repair_assign_manhour: number;
    mi_repair_man_charge: number;
    sch_assign_manhour: number;
    sch_man_charge: number;
}

export class ResourcesData {
    employeeID: number;
    name: string;
    leave_name: string;
    leave_fromDate: Date;
    leave_toDate: Date;
    couseName: string;
    training_fromDate: Date;
    training_toDate: Date;
    order: string;
    hour: number;
    task: string

}