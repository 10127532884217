
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../_services/authentication.service';
import { User } from '../../_models/user';
import { ResponseServlet } from '../../_models/response';

import { version } from '../../../../package.json';
import { HolidayService } from '../../_services/holiday.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})


export class LoginComponent implements OnInit {

  public version: string = version;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  userLogin: User;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private holService: HolidayService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    console.log('version : '+version);
    this.userLogin = new User();
    this.loginForm = new FormGroup({
      "uid": new FormControl(this.userLogin.uid, [Validators.required, Validators.minLength(6)]),
      "password": new FormControl(this.userLogin.password, [Validators.required, Validators.minLength(8), Validators.maxLength(15)])
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }




  get uid() { return this.loginForm.get("uid") };
  get password() { return this.loginForm.get('password') };


  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.userLogin.uid=this.loginForm.value.uid;
    this.userLogin.password=this.loginForm.value.password;
    this.authenticationService.login(this.userLogin).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          let res = new ResponseServlet();
          res = JSON.parse(JSON.stringify(data));
          console.log("status : " + (res.status==200));
          if (res.status === 200) {
           this.userLogin = JSON.parse(res.result);
            localStorage.setItem('currentUser', JSON.stringify(this.userLogin));
            this.router.navigate([this.userLogin.role.url_home]); 
            this.authenticationService.currentUserSubject.next(this.userLogin);
            this.setHolidaysToLocalStorage();
          } else {
            console.log("error "+res.message);
            this.error = res.message;
          }
        },
        error => {
          this.error = error.message;
        });
    this.loading = false;
  }

  setHolidaysToLocalStorage() {
    const storedHolidays = localStorage.getItem("holidays");
  
    if (!storedHolidays) {
      this.error = "";
      this.loading = true;
  
      this.holService.getAll().pipe(first()).subscribe(
        data => {
          console.log(data);
          this.loading = false;
  
          if (!data) {
            this.error = "No data received";
            return;
          }
  
          const res = JSON.parse(JSON.stringify(data));
          console.log(res);
  
          if (res.status === 200) {
            const holidays = JSON.parse(res.result);
            localStorage.setItem('holidays', res.result);
          } else {
            this.error = res.message || "Failed to fetch holidays";
          }
        },
        error => {
          this.loading = false;
          this.error = error.message || "An error occurred";
        }
      );
    }

  }
}