// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // wmtService: 'https://tes-wpt-ark-back-pro.azurewebsites.net/',
    //  wmtService: 'https://tes-wpt-ark-back-uat.azurewebsites.net/',
  // wmtService: 'http://localhost:8080/wmt/',
  wmtService: 'https://mst.scgdow-thailand.com/',
  // wmtService: 'https://tes-wpt-uat.azurewebsites.net/',
  swpService: 'https://iswp.scgdow-thailand.com/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
