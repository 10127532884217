import { NavData } from '../nav/navdata';

export class User {
    uid: string;
    password: string;
    firstname: string;
    lastname: string;
    role: any;
    navBar: NavData[];
    customField: CustomFieldData[];
    siteID:number;

}

export class CustomFieldData {
    fieldName: string;
    status: boolean;
    type: string;
    order: number;
    width:number;
    showText:string;
}
export class UserData {
    uid: string;
    password: string;
    firstName: string;
    lastName: string;
    siteID:number;
    roleID:number;

}