import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { site } from "../_models/site";
import { WorkData, ManualData } from "../_models/work";
import { Role } from "../_models/role";
import { observable, Observable } from "rxjs";
import { param } from "jquery";

@Injectable()
export class WorkService {
    public site: site;
    public workSel: WorkData;
    public workSels: WorkData[];
    public workSelsMul: [];
    public works: WorkData[]=[];
    constructor(private http: HttpClient) {
        this.workSel = new WorkData();
    }

    status = [
        { statusID: 0, statusName: "Blank" },
        { statusID: 1, statusName: "Complete" },
        { statusID: 2, statusName: "Postpone TES" },
        { statusID: 3, statusName: "Postpone OPT" },
        { statusID: 4, statusName: "SC" },
        { statusID: 5, statusName: "Continue" },
    ];

    leadcrafts = [
        { id: 0, name: "Blank" },
        { id: 1, name: "SCH" },
        { id: 2, name: "Helpers" },
        { id: 3, name: "MI" },
        { id: 4, name: "Instrument" },
        { id: 5, name: "Packing" },
        { id: 6, name: "Electrical" },
        { id: 7, name: "Analyzer" },
        { id: 8, name: "Mechanic" },
        { id: 9, name: "Site Service" },
        { id: 10, name: "ECM" },
        { id: 11, name: "Small Project" },
    ];

    editField = [
        "opt_code1",
        // "opt_code2",
        // "opt_code3",
        "duration",
        //   'complete_date',
        "complete_history",
        // "need_procedure",
        "produce_ready",
        "use_procedure",
        "hyperlink",
        "need_full_history",
        "full_history_from_tech",
        // "one_page_history_from_tech",
        // "need_one_page_history",
        "need_report_from_vendor",
        "report_from_vendor",
        "sent_history_to_discipline_lead",
        "return_reviewed_history",
        "complete_history_date",
        "high_elevated_work",
        "job_complete",
        "gk_expectation",
        "gk_priority",
        "schedule_date",
        "wac_execution_date",
        "scf_erection_date",
        "hot_work",
        "insulation",
        "gatekeeper_note",
        "mtn_code1",
        "mtn_code2",
        "mtn_code3",
        "mtn_code4",
        "mtn_code5",
        "mtn_code6",
        "note",
        "reson_of_schedule_change",
        "need_manhour",
        "critical_lifting",
        "confined_space",
        "crane_size",
        "crane",
        "sch_short_text",
        "scaffolding",
        "scaffolding_short_text",
        "status_update",
        "plant_name",
        "own",
        "lead_craft",
        "electrical_hour",
        "electrical_man",
        "helpers_man",
        "helpers_hour",
        "instrument_man",
        "instrument_hour",
        "mechamic_man",
        "mechamic_hour",
        "mi_Inspect_man",
        "mi_Inspect_hour",
        "mi_repair_man",
        "mi_repair_hour",
        "packing_man",
        "packing_hour",
        "ecm_hour",
        "ecm_man",
        "ana_man",
        "ana_hour",
        "sch_man",
        "sch_hour",
        // "swp",
        "sch",
        "completely_assigned_yet",
        "insulation_short_text",
        "man_hour_charge",
        "tech_name_charge",
        // "assign_manhour",
        "ie_assign_manhour",
        "ie_man_charge",
        "ee_assign_manhour",
        "ee_man_charge",
        "me_assign_manhour",
        "me_man_charge",
        "hp_assign_manhour",
        "hp_man_charge",
        "mi_assign_manhour",
        "mi_man_charge",
        "pac_assign_manhour",
        "pac_man_charge",
        "ana_assign_manhour",
        "ana_man_charge",
        "ecm_assign_manhour",
        "ecm_man_charge",
        "mi_repair_assign_manhour",
        "mi_repair_man_charge",
        "sch_assign_manhour",
        "sch_man_charge",
        //'purchase_order'
    ];
    advanceSearch = [
        "actual_release",
        "basic_fin_date",
        "compare_wac_gk_date",
        "complete_date",
        "complete_history_date",
        "crane_size",
        "crane",
        "scaffolding",
        "create_need",
        "create_today",
        "created_on",
        "duration",
        "firstLoad",
        "gk_book_after_need",
        "gk_expectation",
        "insulation",
        "notification_date",
        "percen",
        "priority",
        "referent_date",
        "rel",
        "schedule_date",
        "wac_execution_date",
        "sch_hour",
        "sch_man",
        "full_history_from_tech",
        "return_reviewed_history",
        "report_from_vendor",
        "sent_history_to_discipline_lead",
        "scf_erection_date", 
        "cost_center",
        // "mat1_date",
        // "mat2_date",
        // "mat3_date"
    ];

    isManualField(field) {
        return this.editField.includes(field);
    }

    getAll(site, pageLength, start) {
        console.log(site);
        return this.http.post<any>(
            environment.wmtService + "search_work",
            site
        );
    }

    getYearSearch(year){
        return this.http.post<any>(
            environment.wmtService + "search_work",
            year
        );
    }

    
    // getAll2019(site, pageLength, start) {
    //     console.log(site);
    //     return this.http.post<any>(
    //         environment.wmtService + "search_work2019",
    //         site
    //     );
    // }

    update(param) {
        return this.http.post<any>(
            environment.wmtService + "update_work",
            param
        );
    }

    update_job(param) {
        return this.http.post<any>(
            environment.wmtService + "update_job",
            param
        );
    }
    update_wac(param) {
        return this.http.post<any>(
            environment.wmtService + "update_wac",
            param
        );
    }
    update_GK(param) {
        return this.http.post<any>(environment.wmtService + "update_GK", param);
    }

    update_Need_report_from_vendor(param) {
        return this.http.post<any>(environment.wmtService + "update_need_report_from_vendor", param);
    }

    update_full_history_from_tech(param) {
        return this.http.post<any>(environment.wmtService + "updateFullHistoryFromTech", param);
    }

    update_complete_history_date(param) {
        return this.http.post<any>(environment.wmtService + "updateCompleteHistoryDate", param);
    }

    update_return_reviewed_history(param) {
        return this.http.post<any>(environment.wmtService + "UpdateReturnReviewedHistoryDate", param);
    }

    update_Need_full_history(param) {
        return this.http.post<any>(environment.wmtService + "update_need_full_history", param);
    }


    update_status(param) {
        return this.http.post<any>(
            environment.wmtService + "update_status",
            param
        );
    }

    update_report_from_vendor(param) {
        return this.http.post<any>(
            environment.wmtService + "update_report_from_vendor",
            param
        );
    }
    update_sent_history_to_discipline_lead(param) {
        return this.http.post<any>(
            environment.wmtService + "update_sent_history_to_discipline_lead",
            param
        );
    }
    update_scf_erection_date(param) {
        return this.http.post<any>(
            environment.wmtService + "update_scf_erection_date",
            param
        );
    }

    addResource(param) {
        return this.http.post<any>(
            environment.wmtService + "create_resource",
            param
        );
    }

    addResourceMulti(param) {
        return this.http.post<any>(
            environment.wmtService + "create_resource_multi",
            param
        );
    }

    deleteResource(param) {
        return this.http.post<any>(
            environment.wmtService + "delete_resource",
            param
        );
    }



    updateResource(param) {
        return this.http.post<any>(
            environment.wmtService + "update_resource",
            param
        );
    }

    


    updateIw37(param) {
        return this.http.post<any>(
            environment.wmtService + "insert_charge_hour",
            param
        );
    }

    deleteChargehour(param) {
        return this.http.post<any>(
            environment.wmtService + "delete_chargehour",
            param
        );
    }

    searchResource(param) {
        return this.http.post<any>(
            environment.wmtService + "search_resource",
            param
        );
    }

    searchChargehour(param) {
        return this.http.post<any>(
            environment.wmtService + "search_chargehour",
            param
        );
    }

    // searchWork2019(){  
    //     return this.http.post<any>(
    //         environment.wmtService + "search_work2019",param);
    // };

    updateUpdateCustomField(param) {
        return this.http.post<any>(
            environment.wmtService + "update_customfield",
            param
        );
    }

    searchWorkDetail(param) {
        return this.http.post<any>(
            environment.wmtService + "search_workdetail",
            param
        );
    }

    searchReportDailyAssign(param) {
        return this.http.post<any>(
            environment.wmtService + "search_dailyassign",
            param
        );
    }

    searchReportManhr(param) {
        return this.http.post<any>(
            environment.wmtService + "search_manhr",
            param
        );
    }

    searchScfReport(param) {
        return this.http.post<any>(
            environment.wmtService + "search_scf_report",
            param
        );
    }

    searchReportGkBook(param) {
        return this.http.post<any>(
            environment.wmtService + "search_gkbook",
            param
        );
    }

    searchExportReport1(param) {
        return this.http.post<any>(
            environment.wmtService + "search_export_report1",
            param
        );
    }

    searchMstReport(param) {
        return this.http.post<any>(
            environment.wmtService + "search_mst_report",
            param
        );
    }

    searchdailySchedule(param) {
        return this.http.post<any>(
            environment.wmtService + "search_dailyschedule",
            param
        );
    }

    searchWorkAssignment(param) {
        return this.http.post<any>(
            environment.wmtService + "search_workassignment",
            param
        );
    }

    searchJobAssignment(param) {
        return this.http.post<any>(
            environment.wmtService + "search_workload_jobassignment",
            param
        );
    }

    searchLeaveAndOthers(param) {
        return this.http.post<any>(
            environment.wmtService + "search_workload_leave",
            param
        );
    }

    searchSumOfManHour(param) {
        return this.http.post<any>(
            environment.wmtService + "search_workload_sumofmanhour",
            param
        );
    }

    searchSumOfChargeHour(param) {
        return this.http.post<any>(
            environment.wmtService + "search_sumofchargehour",
            param
        );
    }

    deleteWork(param) {
        return this.http.post<any>(
            environment.wmtService + "delete_work",
            param
        );
    }

    downloadWork(param) {
        return this.http.post<any>(
            environment.wmtService + "download_work",
            param
        );
    }

    export(uid): Observable<Object[]> {
        return Observable.create((observer) => {
            let xhr = new XMLHttpRequest();
            xhr.open(
                "GET",
                environment.wmtService + "download_work?uid=" + uid,
                true
            );
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.responseType = "blob";

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        var blob = new Blob([xhr.response], {
                            type: contentType,
                        });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send();
        });
    }

    exportmanhr(uid, param, fromDate, toDate): Observable<Object[]> {
        return Observable.create((observer) => {
            let xhr = new XMLHttpRequest();
            // return this.http.post<any>(environment.wmtService + "search_manhr", param)
            xhr.open(
                "GET",
                environment.wmtService +
                "download_manhr?uid=" +
                uid +
                "&param=" +
                param +
                "&fromDate=" +
                fromDate +
                "&toDate=" +
                toDate,
                true
            );
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.responseType = "blob";

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        var blob = new Blob([xhr.response], {
                            type: contentType,
                        });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send();
        });
    }

    downloadScaffolding(uid, param, fromDate, toDate): Observable<Object[]> {
        return Observable.create((observer) => {
            let xhr = new XMLHttpRequest();
            // return this.http.post<any>(environment.wmtService + "search_manhr", param)
            xhr.open(
                "GET",
                environment.wmtService +
                "download_scf_report?uid=" +
                uid +
                "&param=" +
                param +
                "&fromDate=" +
                fromDate +
                "&toDate=" +
                toDate,
                true
            );
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.responseType = "blob";

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        var blob = new Blob([xhr.response], {
                            type: contentType,
                        });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send();
        });
    }

    exportgkbook(uid, param): Observable<Object[]> {
        return Observable.create((observer) => {
            let xhr = new XMLHttpRequest();
            // return this.http.post<any>(environment.wmtService + "search_manhr", param)
            xhr.open(
                "GET",
                environment.wmtService +
                "download_gkbook?uid=" +
                uid +
                "&param=" +
                param,
                true
            );
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.responseType = "blob";

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        var blob = new Blob([xhr.response], {
                            type: contentType,
                        });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send();
        });
    }

    exportscf(uid, param, fromDate, toDate): Observable<Object[]> {
        return Observable.create((observer) => {
            let xhr = new XMLHttpRequest();
            xhr.open(
                "GET",
                environment.wmtService +
                "export_scf_report?uid=" +
                uid +
                "&param=" +
                param +
                "&fromDate=" +
                fromDate +
                "&toDate=" +
                toDate,
                true
            );
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.responseType = "blob";

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        var blob = new Blob([xhr.response], {
                            type: contentType,
                        });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send();
        });
    }

    exportmst(uid, param, fromDate, toDate): Observable<Object[]> {
        return Observable.create((observer) => {
            let xhr = new XMLHttpRequest();
            xhr.open(
                "GET",
                environment.wmtService +
                "export_mst_report?uid=" +
                uid +
                "&param=" +
                param +
                "&fromDate=" +
                fromDate +
                "&toDate=" +
                toDate,
                true
            );
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.responseType = "blob";

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        var blob = new Blob([xhr.response], {
                            type: contentType,
                        });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send();
        });
    }

    export_scf_ftp(param) {
        return this.http.post<any>(environment.wmtService + "ftp_scf", param);
    }

    export_mst_ftp(param) {
        return this.http.post<any>(environment.wmtService + "ftp_mst", param);
    }

    download_mst(uid, param, fromDate, toDate): Observable<Object[]> {
        return Observable.create((observer) => {
            let xhr = new XMLHttpRequest();
            xhr.open(
                "GET",
                environment.wmtService +
                "download_mst?uid=" +
                uid +
                "&param=" +
                param +
                "&fromDate=" +
                fromDate +
                "&toDate=" +
                toDate,
                true
            );
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.responseType = "blob";

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        var blob = new Blob([xhr.response], {
                            type: contentType,
                        });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send();
        });
    }

    searchLeadCraft(param) {
        return this.http.post<any>(
            environment.wmtService + "search_leadcraft",
            param
        );
    }

    searchDataFillter(param) {
        return this.http.post<any>(
            environment.wmtService + "SearchDataFillter",
            param
        );
    }

    searchLeaveWorkAssign(param) {
        return this.http.post<any>(
            environment.wmtService + "search_leavetrain_workassign",
            param
        );
    }

    searchActionLog(param) {
        return this.http.post<any>(
            environment.wmtService + "search_actionlog",
            param
        );
    }

        searchLogs(param) {
        return this.http.post<any>(
            environment.wmtService + "search_action_logs",
            param
        );
    }

    updateSWP(param) {
        return this.http.post<any>(
            environment.wmtService + "create_swp",
            param
        );
    }
    updateSWPStatus(param) {
        return this.http.post<any>(
            environment.wmtService + "update_status_update",
            param
        );
    }

    loadSkillByEmp(param) {
        return this.http.post<any>(
            environment.wmtService + "search_skill_by_emp",
            param
        );
    }
}
