import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value): string {
    let date: string = value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : 'no timestamp';
    return date;
  }

}
